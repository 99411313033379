import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import HomeOwlSlider from './../Element/HomeOwlSlider';
import FormStyle from './../Element/FormStyle';

//Images
import pic4 from './../../images/about/pic4.jpg';
import bgimg from './../../images/background/bg1.jpg';
import bgimg2 from './../../images/background/bg-map.jpg';
import bgimg3 from './../../images/background/bg1.jpg';
import work1 from './../../images/our-work/pic1.jpg';
import work3 from './../../images/our-work/pic3.jpg';
import work5 from './../../images/our-work/pic5.jpg';
import {wraperBlog, wraperBox2, wraperBox3, wraperBox4,
    wraperBox5, wraperBox6, wraperBox7, wraperBox8,
    dlabBox, dlabBox2,} from '../Pages/ShortCode/DataFile';

import team1 from './../../images/our-team/pic1.jpg';
import team2 from './../../images/our-team/pic2.jpg';
import team3 from './../../images/our-team/pic3.jpg';
import team4 from './../../images/our-team/pic4.jpg';
import team5 from './../../images/our-team/pic5.jpg';
import team6 from './../../images/our-team/pic6.jpg';
import team7 from './../../images/our-team/pic7.jpg';
import team8 from './../../images/our-team/pic8.jpg';
import team9 from './../../images/our-team/pic9.jpg';
// import team10 from './../../images/our-team/pic10.jpg';


const teamBlog = [
	{image: team1,  name: "RK Aadil", post: "1.3 Millions+ Followers", socialMedia: [{icon: "fa fa-youtube-play", url:"https://www.youtube.com/@RkAadil/about"},{icon: "fa fa-facebook", url:"https://facebook.com"}, {icon: "fa fa-instagram", url:"https://instagram.com/rk.aadil/"}]},
	{image: team2,  name: "Shubham Mishra", post: "2 Lakh+ Followers", socialMedia: [{icon: "", url:""},{icon: "fa fa-facebook", url:"https://www.facebook.com/hoslebuland.official"}, {icon: "fa fa-instagram", url:"https://www.instagram.com/badassshubhammm/"}]},
	{image: team3,  name: "Abbas Alizada", post: "1 Millions Followers", socialMedia: [{icon: "fa fa-youtube-play", url:"https://www.youtube.com/watch?v=Bj0LCHnJkrk"},{icon: "", url:""}, {icon: "fa fa-instagram", url:"https://www.instagram.com/official_abbli/"}]},
	{image: team5,  name: "Ryusei Imai", post: "10 Millions Followers", socialMedia: [{icon: "fa fa-youtube-play", url:"https://www.youtube.com/results?search_query=ryusei+imai"},{icon: "fa fa-facebook", url:"https://www.facebook.com/BruceLee/videos/3309894692591291"}, {icon: "fa fa-instagram", url:"https://www.instagram.com/ryusei416japan/"}]},
	{image: team4,  name: "Barodian Bapu", post: "5.3 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/barodian_bapu/"},{icon: "fa fa-youtube-play", url:"https://www.youtube.com/@barodianbapu5430"}]},
	{image: team9, name: "Krunal Gohil", post: "2.8 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/kunnu_3498/"},{icon: "fa fa-youtube-play", url:"https://www.youtube.com/@kunnu8176"}]},
	{image: team7,  name: "Dhruv Solanki", post: "3.2 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/d.solanki_official_01/"}]},
	{image: team6,  name: "Darshan Solanki", post: "2 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/darshu6766/"},{icon: "fa fa-youtube-play", url:"https://www.youtube.com/@vadodaranimoj915"}]},
	
	// {image: team8,  name: "Shastri Sarkar", post: "4.1 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/ryusei416japan/"},{icon: "fa fa-youtube-play", url:"https://www.youtube.com/results?search_query=ryusei+imai"}]},
	{image: team8,  name: "Sumit Sinh Parmar", post: "1.1 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/sumit__barodaboys49/"}]},
	
	
];

class Homepage extends Component{
	state = {
		numCards: 4
	  }
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper">
				<Header />
				<div className="page-content bg-white">
					{/*  Slider Banner */}
					<div className="owl-slider-banner main-slider">
						<HomeOwlSlider />
						{/*  Service   */}

						{/*  Service End  */}
					</div>
					{/*  Slider Banner */}
					<div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full bg-white content-inner-1 about-us">
                        <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-uppercase m-b10">Who We Are</h2>
                                    <p>TechModulus is an India-based software development and digital marketing Agency founded in 2020. We empower businesses with innovative software solutions and effective marketing strategies to achieve their digital goals.</p>
                                </div>
                                <div className="row ">
									{wraperBox3.map((item,index)=>(
										<div className="col-md-4 col-sm-6 m-b30" key={index}>
											<div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
												<div className="icon-lg m-b20"> <Link to={"#"} className="icon-cell">{item.icon}</Link> </div>
												<div className="icon-content">
													<h5 className="dlab-tilte text-uppercase"><Link to={"#"}>{item.title}</Link></h5>
													<p>{item.description}</p>
												</div>
											</div>
										</div>
									))}	
                                </div>

                            </div>
                        </div>

                        {/* <!-- Creators Managed by Us --> */}
						<div className="section-full content-inner bg-gray creators-section">
  <div className="container">
    <div className="row">
      <div className="col-md-12 text-center section-head">
        <h2 className="font-weight-700 m-b0">Creators on the Rise</h2>
        <p className="m-b0">Creators Who Grew with Our Social Media and Digital Marketing Services</p>
      </div>
    </div>
    <div className="row">
      {teamBlog.slice(0, this.state.numCards).map(({ name, image, post, socialMedia }, index) => (
        <div className="col-lg-3 col-md-6 col-sm-6 m-b30" key={`${name}-${index}`}>
          <div className="team-section">
            <img src={image} alt={name} />
            <div className="team-content text-white">
              <h6 className="text-uppercase text-black m-b0">{name}</h6>
              <p className="m-a0">{post}</p>
              <div className="dlab-divider bg-white mt-2 mb-2"></div>
              <ul className="list-inline m-a0">
                {socialMedia?.map(({ url, icon }, index) => (
                  <li key={`${name}-social-${index}`}>
                    <a href={url} target="_blank" className="text-white"><i className={icon}></i></a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
    {teamBlog.length > 4 && (
      <div className="text-center">
        {this.state.numCards < teamBlog.length ? (
          <button className="dez-page site-button primary" onClick={() => this.setState({ numCards: this.state.numCards + 8 })}>Show More</button>
        ) : (
          <button className="dez-page site-button primary" onClick={() => this.setState({ numCards: 4 })}>Show Less</button>
        )}
      </div>
    )}
  </div>
</div>

                          {/* <!-- Creators Managed by Us End--> */}
                        {/* <!-- About Us End --> */}




                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bgimg3 + ")" }}>
                            <FormStyle />
                        </div>
                        {/* <!-- Get in touch --> */}
						
					</div>
				</div>	
				<Footer />
				
			</div>		
		)
	}
}
export default Homepage;