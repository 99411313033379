import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";

import Slider1 from './../../images/main-slider/slide1.jpg';
import Slider2 from './../../images/main-slider/slide2.jpg';
import Slider3 from './../../images/main-slider/slide3.jpg';


function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next la la-angle-right"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
		<div className=" owl-prev la la-angle-left" onClick={onClick} style={{zIndex:1 }}/>
	</div>
  );
}

class HomeOwlSlider extends Component{
	
	render(){
		var settings = {		
			arrows: true,
			dots: true,
            slidesToShow: 1,			
            infinite: true,
			autoplay: true,
			autoplaySpeed: 6000, 
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
					
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
					
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
					
				  }
				}
			]
        };
		return(
			
			<Slider className="owl-slider owl-carousel owl-theme owl-none" {...settings}>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider1} className="w-100" alt=""/></div>
					<div className="slide-content overlay-primary">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">Innovative Software Solutions<br/> for Modern Business Needs.<br/></h2>
								<p>  Collaborate with our expert team for bespoke software development, including web and mobile app development, software integration, and maintenance services. We'll help you innovate, build, and scale your business to new heights.</p>
								<Link to ={"/contact"} className="site-button m-r10 white button-lg">Contact Us</Link>
								{/* <Link to ={"#"} className="site-button outline outline-2 button-lg">How It Work</Link> */}
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider3} className="w-100" alt=""/></div>
					<div className="slide-content overlay-primary">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">Empower Your Online Presence<br/> with Strategic Digital Marketing. <br/></h2>
								<p> Our effective digital marketing strategies, including SEO, PPC, social media, and email campaigns, help you build a strong online presence and increase revenue. Discover how we can help your business grow today.</p>
								<Link to ={"/contact"} className="site-button m-r10 white button-lg">Contact Us</Link>
							
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider2} className="w-100" alt=""/></div>
					<div className="slide-content overlay-primary">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">Elevate Your Social Media Presence<br/> and Income 3x with Us. </h2>
								<p>Maximize your social media impact, grow your online presence and elevate your income 3x with our expert content creation, scheduling, and community management services - partner with us today and watch your social media soar.</p>
								<Link to ={"/contact"} className="site-button m-r10 white button-lg">Contact Us</Link>
							</div>
						</div>
					</div>
				</div>
			</Slider>	
		)
		
	}
	
}

export default HomeOwlSlider;