import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';
import {VisionBlog} from './Aboutus/Aboutus1';


import team1 from './../../images/our-team/pic1.jpg';
import team2 from './../../images/our-team/pic2.jpg';
import team3 from './../../images/our-team/pic3.jpg';
import team4 from './../../images/our-team/pic4.jpg';
import team5 from './../../images/our-team/pic5.jpg';
import team6 from './../../images/our-team/pic6.jpg';
import team7 from './../../images/our-team/pic7.jpg';
import team8 from './../../images/our-team/pic8.jpg';
import team9 from './../../images/our-team/pic9.jpg';
import bnr from './../../images/banner/bnr7.jpg';

const teamBlog = [
	{image: team1,  name: "RK Aadil", post: "1.3 Millions+ Followers", socialMedia: [{icon: "fa fa-youtube-play", url:"https://www.youtube.com/@RkAadil/about"},{icon: "fa fa-facebook", url:"https://facebook.com"}, {icon: "fa fa-instagram", url:"https://instagram.com/rk.aadil/"}]},
	{image: team2,  name: "Shubham Mishra", post: "2 Lakh+ Followers", socialMedia: [{icon: "", url:""},{icon: "fa fa-facebook", url:"https://www.facebook.com/hoslebuland.official"}, {icon: "fa fa-instagram", url:"https://www.instagram.com/badassshubhammm/"}]},
	{image: team3,  name: "Abbas Alizada", post: "1 Millions Followers", socialMedia: [{icon: "fa fa-youtube-play", url:"https://www.youtube.com/watch?v=Bj0LCHnJkrk"},{icon: "", url:""}, {icon: "fa fa-instagram", url:"https://www.instagram.com/official_abbli/"}]},
	{image: team5,  name: "Ryusei Imai", post: "10 Millions Followers", socialMedia: [{icon: "fa fa-youtube-play", url:"https://www.youtube.com/results?search_query=ryusei+imai"},{icon: "fa fa-facebook", url:"https://www.facebook.com/BruceLee/videos/3309894692591291"}, {icon: "fa fa-instagram", url:"https://www.instagram.com/ryusei416japan/"}]},
	{image: team4,  name: "Barodian Bapu", post: "5.3 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/barodian_bapu/"},{icon: "fa fa-youtube-play", url:"https://www.youtube.com/@barodianbapu5430"}]},
	{image: team9, name: "Krunal Gohil", post: "2.8 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/kunnu_3498/"},{icon: "fa fa-youtube-play", url:"https://www.youtube.com/@kunnu8176"}]},
	{image: team7,  name: "Dhruv Solanki", post: "3.2 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/d.solanki_official_01/"}]},
	{image: team6,  name: "Darshan Solanki", post: "2 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/darshu6766/"},{icon: "fa fa-youtube-play", url:"https://www.youtube.com/@vadodaranimoj915"}]},
	
	// {image: team8,  name: "Shastri Sarkar", post: "4.1 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/ryusei416japan/"},{icon: "fa fa-youtube-play", url:"https://www.youtube.com/results?search_query=ryusei+imai"}]},
	{image: team8,  name: "Sumit Sinh Parmar", post: "1.1 Lakh+ Followers", socialMedia: [{icon: "fa fa-instagram", url:"https://www.instagram.com/sumit__barodaboys49/"}]},
	
	
];

class Team extends Component {
	componentDidMount() {
    }
    render() {
        return (
            <>
                <Header />
                <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
                        <PageTitle motherMenu='Creators On The Rise' activeMenu='Creators'  />
                    </div>
                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    {/* <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
                        <PageTitle motherMenu='Our Team' activeMenu='Our Team'  />
                    </div> */}
                    {/* <!-- inner page banner END --> */}
                    {/* <!-- contact area --> */}
                    <div className="content-block">
                        {/* <!-- Team Section --> */}
						<div className="section-full content-inner bg-gray creators-section">
  <div className="container">
    <div className="row">
      <div className="col-md-12 text-center section-head">
        {/* <h2 className="font-weight-700 m-b0">Creators on the Rise</h2> */}
        <p className="m-b0">Creators Who Grew with Our Social Media and Digital Marketing Services</p>
      </div>
    </div>
    <div className="row">
      {teamBlog.map(({ name, image, post, socialMedia }, index) => (
        <div className="col-lg-3 col-md-6 col-sm-6 m-b30" key={`${name}-${index}`}>
          <div className="team-section">
            <img src={image} alt={name} />
            <div className="team-content text-white">
              <h6 className="text-uppercase text-black m-b0">{name}</h6>
              <p className="m-a0">{post}</p>
              <div className="dlab-divider bg-white mt-2 mb-2"></div>
              <ul className="list-inline m-a0">
                {socialMedia?.map(({ url, icon }, index) => (
                  <li key={`${name}-social-${index}`}>
                    <a href={url} target="_blank" className="text-white"><i className={icon}></i></a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>

  </div>
</div>
                        {/* <!-- Team Section END --> */}
            
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default Team;