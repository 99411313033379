import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';

// var bg1 = require('images/banner/bnr2.jpg');
import bnr1 from './../../../images/banner/bnr1.jpg';
import bnr2 from './../../../images/background/bg1.png';
import about1 from './../../../images/about/pic1.jpg';
import about2 from './../../../images/about/pic2.jpg';
import about3 from './../../../images/about/pic3.jpg';
import bnr3 from './../../../images/pattern/pic1.jpg';
import work1 from './../../../images/our-work/pic1.jpg';
import work3 from './../../../images/our-work/pic3.jpg';
import work5 from './../../../images/our-work/pic5.jpg';
import bnr4 from './../../../images/background/bg1.jpg';

const imageblog =[
	{image: work1, title:'Comprehensive Solutions', description:'TechModulus offers a complete solution for businesses and creators\' digital needs with comprehensive digital marketing and software development services.' },
	{image: work3, title:'Client-Focused Approach', description: 'TechModulus prioritizes clients and customizes services to individual needs, working closely with them to understand goals and achieve full potential.'},
	{image: work5, title:'Results-Driven Approach', description:' TechModulus\'s results-oriented approach delivers measurable results, improving website traffic, search engine rankings, and social media engagement for businesses and creators. .'},
];
const counterBlog = [
	{icon: <i className="icon flaticon-bar-chart text-primary" />, num: '4922',  title: 'Completed Projects',},	
	{icon: <i className="icon flaticon-avatar text-primary" />, num: '2934',    title: 'Happy Clients',},	
	{icon: <i className="icon flaticon-file text-primary" />, num: '2296',   title: 'Questions Answered',},	
	{icon: <i className="icon flaticon-pen text-primary" />, num: '1343', title: 'Ordered Coffees',},	
];

class Aboutus2 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='About TechModulus' activeMenu='About TechModulus' />
                    </div>
                    <div className="content-block">
                    <div className="section-full content-inner" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    {/* <h2 className="text-uppercase m-b10">About Agency</h2> */}
                                    <p>TechModulus is an India-based software development and digital marketing Agency founded in 2020. We empower businesses with innovative software solutions and effective marketing strategies to achieve their digital goals.</p>
                                </div>
                                <div className="row text-center">
									{imageblog.map((item,index)=>(
										<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
											<div className="dlab-box">
												<div className="dlab-media dlab-img-effect rotate"> <Link to ={"#"}><img src={item.image} alt="" /></Link> </div>
												<div className="dlab-info p-a30 border-1 bg-white">
													<h5 className="dlab-title m-t0"><Link to ={"#"}>{item.title}</Link></h5>
													<p className="m-b0">{item.description}</p>
												</div>
											</div>
										</div>
									))}
                                </div>
                            </div>
                        </div>
                        <div className="section-full ">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h2>Empowering businesses and creators with comprehensive digital solutions</h2>
                                                        <h5 className="m-b30">TechModulus is a digital marketing and software development company that provides comprehensive solutions to help businesses and creators grow.</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 text-gray">
                                                      
                                                        <p>With a focus on driving growth and success for its clients, TechModulus offers a range of digital marketing services such as social media management, search engine optimization, content marketing, and paid advertising, as well as software development services such as website development, mobile app development, and custom software development. The company is committed to putting its clients first, working closely with them to understand their unique needs and goals, and tailoring its services to meet their specific requirements. At TechModulus, we empower businesses and creators with comprehensive digital solutions that help them achieve their full potential.</p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                        <img src={about3} alt="" className="img-cover" />
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="section-full p-tb80">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center max-w600 m-auto ">
                                            <div className="m-b20"><i className="fa fa-quote-left font-45 text-primary"></i></div>
                                            <h4 className="text-uppercase font-weight-700 font-30">Maximize your digital potential with TechModulus?</h4>
                                            <Link to={"/contact"} className="site-button radius-xl outline outline-2"><span className="p-lr10">Contact Us</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
export default Aboutus2;