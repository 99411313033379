import React,{Component} from 'react';			

export default class ThemeButton extends Component{
	render(){
		return(
			<>
				
			</>
		)
	}	
}
